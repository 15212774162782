<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:body>
                <b-row>
                    <b-col lg="6" sm="12">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('germplasm.access_no')"
                        label-for="accession_number"
                        >
                        <b-form-input
                            id="org_name"
                             v-model="search.accession_number"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('germplasm.gid_no')"
                        label-for="gid_no"
                        >
                        <b-form-input
                            id="org_name"
                             v-model="search.gid_no"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('germplasm.characterization')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                        <b-button variant="primary btn_add_new">
                            <router-link :to="{ path: '/seeds-fertilizer-service/germplasm-germplasm/characterization-add'}" size="sm">
                                <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
                            </router-link>
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(type_name)="data">
                                        <span class="capitalize">{{ data.item.type_name }}</span>
                                        </template>
                                        <template v-slot:cell(crop_name)="data">
                                        <span class="capitalize">{{ data.item.crop_name }}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <!-- <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button> -->
                                          <!--   <span>
                                                <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="fas fa-window-close m-0"></i></b-button>
                                            </span> -->
                                            <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" title="Edit">
                                                <router-link :to="{ path: '/seeds-fertilizer-service/germplasm-germplasm/characterization-add', query: { id: data.item.id }}" size="sm">
                                                        <i class="ri-ball-pen-fill m-0"></i>
                                                </router-link>
                                            </b-button>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>

    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { characList, characToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form
    },
    data () {
        return {
            search: {
                accession_number: '',
                gid_no: '',
                org_id: this.$store.state.dataFilters.orgId
            },
            cropNameList: []
        }
    },
    computed: {
        CropTypeList: function () {
          return this.$store.state.SeedsFertilizer.commonObj.CropTypeList.filter(item => item.status === 1)
        },
        formTitle () {
            return (this.editItemId === 0) ? this.$t('germplasm.characterization') + ' ' + this.$t('globalTrans.entry') : this.$t('germplasm.characterization') + ' ' + this.$t('globalTrans.update')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('germplasm.access_no'), class: 'text-center' },
                { label: this.$t('germplasm.gid_no'), class: 'text-center' },
                { label: this.$t('germplasm.crop_type'), class: 'text-center' },
                { label: this.$t('germplasm.crop'), class: 'text-center' },
                // { label: this.$t('germplasm.characterType'), class: 'text-left' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'accession_number' },
                { key: 'gid_no' },
                { key: 'crop_type_bn' },
                { key: 'crop_name_bn' },
                { key: 'action' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'accession_number' },
                { key: 'gid_no' },
                { key: 'crop_type' },
                { key: 'crop_name' },
                { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
        this.loadData()
    },
    mounted () {
        core.index()
    },
    watch: {
        'search.crop_type_id': function (newValue){
            this.cropLists(newValue)
        }
    },
    methods: {
        cropLists (cropTypeID) {
            const cropName = this.$store.state.SeedsFertilizer.commonObj.CropNameList.filter(item => item.status === 1 && item.crop_type_id === cropTypeID.value)
            this.cropNameList = cropName
        },
        dataChange () {
            this.loadData()
        },
        async searchData () {
            this.loadData()
        },
        remove (item) {
        this.changeStatus(seedFertilizerServiceBaseUrl, characToggleStatus, item, 'seeds_fertilizer', 'CropNameList')
        },
        loadData () {
            const params = Object.assign({}, this.search, { accession_number: this.search.accession_number, page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(seedFertilizerServiceBaseUrl, characList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getCustomDataList(data) {
        const listData = data.map(item => {
            const cropNameObj = this.$store.state.SeedsFertilizer.commonObj.CropNameList.find(doc => doc.value === parseInt(item.crop_name_id))
            const cropTypeObj = this.$store.state.SeedsFertilizer.commonObj.CropTypeList.find(doc => doc.value === parseInt(cropNameObj.crop_type_id))
            const customiseData = {}
            if(typeof cropTypeObj !== 'undefined') {
              customiseData.crop_type = cropTypeObj.text_en
              customiseData.crop_type_bn = cropTypeObj.text_bn
            } else {
              customiseData.crop_type = ''
              customiseData.crop_type_bn = ''
            }
            if(typeof cropNameObj !== 'undefined') {
              customiseData.crop_name = cropNameObj.text_en
              customiseData.crop_name_bn = cropNameObj.text_bn
            } else {
              customiseData.crop_name = ''
              customiseData.crop_name_bn = ''
            }
            return Object.assign({}, item, customiseData)
          })
          return listData
        }
    }
}
</script>
